<script>
    import { onMount } from 'svelte';
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	onMount(() => {
		anime.timeline({loop: false})
		.add({
			targets: '.ml8 .letters-left',
			scale: [0, 1],
			duration: 1200,
			offset: '+=550'
		}).add({
			targets: '.ml8 .letters-left',
			scale: 0,
			duration: 1000,
			easing: "easeInElastic",
			delay: 800,
			complete: () => { dispatch('done'); }
		});
	});
</script>

<style>
	h1.ml8 {
		font-weight: 900;
		font-size: 4.5em;
		color: #fff;
		width: 3em;
		height: 3em;
	}

	.ml8 .letters-container {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: 0;
		bottom: 0;
		height: 1em;
		text-align: center;
	}

	.ml8 .letters {
		position: relative;
		z-index: 2;
		display: inline-block;
		line-height: 0;
		right: 0;
		top: -43px;
	}

	.ml8 .circle {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: 0;
		bottom: 0;
	}
</style>

<h1 class="ml8">
	<span class="letters-container">
		<span class="letters letters-left"><slot/></span>
	</span>
	<span class="circle circle-white"></span>
</h1>