<script>
    import { Router, Link, Route } from 'svelte-routing';

    import Login from './routes/Login.svelte';
    import Quiz from './routes/Quiz.svelte';
    import Scores from './routes/Scores.svelte';

    export let url = '';
</script>

<Router url="{url}">
    <nav>
        <Link to="auth">Login</Link>
        <Link to="/">Quiz</Link>
        <Link to="score">Scores</Link>
    </nav>

    <div>
        <Route path="auth" component="{Login}" />
        <Route path="score" component="{Scores}" />
        <Route path="/" component="{Quiz}" />
    </div>
</Router>