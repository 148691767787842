<script>
    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';

    export let location;

    onMount(auth);
    
    function auth() {
        if (!localStorage.getItem('username')) {
            navigate('/auth');
        }
    }
</script>

<p>Scoreboard</p>