class Api {
    constructor() {
        if (!process.env.API_URL) throw 'API_URL must be set';
        this.API_URL = process.env.API_URL;
    }

    apiUrl(path) {
        return `${this.API_URL}${path}`;
    }

    async get(path) {
        return fetch(this.apiUrl(path));
    }

    async getData(path) {
        const res = await this.get(path);
        
        if (res.status !== 200 && res.status !== 201) {
            return null;
        }

        const data = await res.json();
        
        if (data?.data && Object.keys(data.data)) {
            return data.data;
        }

        return null;
    }

    async fetchUser(username) {
        return await this.getData(`user?username=${username}`);
    }

    async fetchBeers(username) {
        return await this.getData(`beers?username=${username}`);
    }
}

export default Api;