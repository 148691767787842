<script>
    import { navigate } from 'svelte-routing';

    import Api from '../api';
    import Spinner from '../components/Spinner.svelte';
    import Popper from '../components/Popper.svelte';

    export let location;

    const api = new Api();
    let username = '';
    let isAuthing = false;
    let isDone = false;
    let avatar = null;

    async function auth() {
        isAuthing = true;

        if (!username.trim().length) {
            // toast
            console.error('invalid username');
            return;
        }

        const userProps = await api.fetchUser(username);

        if (!userProps) {
            // toast
            console.error('error authing');
            return;
        }

        avatar = userProps.user_avatar_hd;
        sessionStorage.setItem('user', JSON.stringify({ username, avatar, friends: userProps.friends }));

        const userAndFriends = [username, ...userProps.friends.map(u => u.username)];

        for (const i in userAndFriends) {
            await api.fetchBeers(userAndFriends[i]); // in parallel causes 429
        }
        
        const img = new Image();
        img.src = avatar;
        img.onload = () => isDone = true;
    }
</script>

<div class="modal is-active">
    <div class="modal-content">
        {#if !isAuthing}
            <input 
                type="text" 
                class="input is-large has-primary-background-color has-highlight-color has-text-centered"
                placeholder="Your Untappd username.." 
                disabled={isAuthing}
                bind:value={username} 
                on:keyup={e => e.key === 'Enter' && auth()}
            />
        {:else if !isDone}
            <Spinner />   
        {/if}
    </div>
</div>


{#if isDone}
    <Popper on:done={() => navigate('/')}>
        <img 
            class="is-round"
            src={avatar}
            width="159"
            height="159"
            >
    </Popper>
{/if}