<script>
    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';

    export let location;
    const userProps = JSON.parse(sessionStorage.getItem('user'));

    onMount(auth);
    
    function auth() {
        if (!userProps) {
            navigate('/auth');
        }
    }
</script>

<p>Quizzing as {userProps?.username}</p>